import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { logoutUser, verifyUserDetails } from "../../store/auth/authActions";

const Navbar = () => {
	const dispatch = useDispatch();
	const { user, accessToken } = useSelector((state) => state.auth);

	useEffect(() => {
		if (accessToken) {
			dispatch(verifyUserDetails())
				.unwrap()
				.catch((error) => {
					dispatch(logoutUser());
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logoutHandler = () => {
		dispatch(logoutUser());
	};

	return (
		<header style={{ paddingTop: "20px", paddingBottom: "20px" }}>
			<div className="flex justify-between p-4" style={{ background: "#eee", color: "#000", borderRadius: "15px" }}>
				<span>
					<b style={{ color: "#772aff" }}>COACH LUCK ACADEMY</b> {user ? `Logged in as ${user.email}` : "Please login"}
				</span>
				<div>
					{user ? (
						<NavLink onClick={logoutHandler} style={{ textDecoration: "none" }}>
							Logout
						</NavLink>
					) : (
						<NavLink to="/login" style={{ textDecoration: "none" }}>
							Login
						</NavLink>
					)}
				</div>
			</div>
			{/* <nav><ul><li><NavLink to="/">Home</NavLink></li></ul></nav> */}
		</header>
	);
};

export { Navbar };
